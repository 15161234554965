import { useQuery } from "@tanstack/react-query";
import { Document } from "@contentful/rich-text-types";
import ContentLoader from "react-content-loader";
import { contentfulClient } from "@/utils/contentful";
import ContentfulRichText from "./ContentfulRichText";
import type { EntryFieldTypes } from "contentful";

interface MessageSkeleton {
  contentTypeId: "message";
  fields: {
    name: EntryFieldTypes.Text;
    content: EntryFieldTypes.RichText;
  };
}

const ContentfulMessageContent: React.FC<{
  entryId: string;
  className?: string | undefined;
}> = ({ entryId, className }) => {
  const query = useQuery(["message", entryId], async () =>
    contentfulClient.getEntry<MessageSkeleton>(entryId)
  );

  if (query.isLoading) {
    return (
      <ContentLoader height={120} style={{ width: "100%" }}>
        <rect x="0" y="17" rx="4" ry="4" width="300" height="13" />
        <rect x="0" y="40" rx="3" ry="3" width="250" height="10" />
        <rect x="0" y="60" rx="3" ry="3" width="250" height="10" />
      </ContentLoader>
    );
  }

  if (!query.data) {
    return <div>Not found</div>;
  }

  return (
    <div className={className}>
      <ContentfulRichText content={query.data.fields.content} />
    </div>
  );
};

export default ContentfulMessageContent;
