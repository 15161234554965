export type SearchState = {
  query: string;
  refinementList: {
    [key: string]: string[];
  };
};

const defaultSearchState: SearchState = {
  query: "",
  refinementList: {},
};

let currentSearchState: SearchState = defaultSearchState;

export const searchStorage = {
  save(state: SearchState): void {
    currentSearchState = state;
  },
  load(): SearchState {
    return currentSearchState;
  },
};
