import { Fragment, useMemo, useRef } from "react";
import { Popover, Switch, Transition } from "@headlessui/react";
import { states } from "../utils/states";
import keyBy from "lodash/keyBy";
import { useRefinementList } from "react-instantsearch";
import { useFuzzy } from "react-use-fuzzy";
import FunnelIcon from "@heroicons/react/24/outline/FunnelIcon";
import FunnelIconSolid from "@heroicons/react/24/solid/FunnelIcon";
import ChevronDownIcon from "@heroicons/react/24/solid/ChevronDownIcon";
import cs from "classnames";
import ToggleSwitch from "./ui/ToggleSwitch";

const statesByAbbreviationMap = keyBy(states, "abbreviation");

const StateRefinement: React.FC = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { items: refinementListRaw, refine } = useRefinementList({
    attribute: "state",
    operator: "or",
    sortBy: ["name:asc"],
    limit: 50,
  });

  const refinementListWithLabels = useMemo(
    () =>
      refinementListRaw.map((item) => ({
        ...item,
        label: statesByAbbreviationMap[item.value]?.name ?? item.value,
      })),
    [refinementListRaw],
  );

  const {
    result: items,
    search,
    keyword,
  } = useFuzzy(refinementListWithLabels, {
    keys: ["value", "label"],
  });

  const selected = useMemo(
    () => items.filter((item) => item.isRefined),
    [items],
  );

  return (
    <Popover
      as="div"
      className="relative inline-block whitespace-nowrap text-left"
    >
      <div>
        <Popover.Button className="inline-flex w-full flex-nowrap justify-center whitespace-nowrap rounded-md bg-gray-200 px-4 py-2 text-sm font-medium text-gray-800 hover:bg-gray-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
          <span className="hidden font-bold sm:inline">
            {selected.length > 0 ? (
              <FunnelIconSolid
                className="mr-2 inline h-5 w-5 text-gray-600"
                aria-hidden="true"
              />
            ) : (
              <FunnelIcon
                className="mr-2 inline h-5 w-5 text-gray-600"
                aria-hidden="true"
              />
            )}
            Filter by State
            <ChevronDownIcon
              className="-mr-1 ml-2 inline h-5 w-5 text-gray-400 hover:text-gray-800"
              aria-hidden="true"
            />
          </span>
          <span className="sm:hidden">
            {selected.length > 0 ? (
              <FunnelIconSolid
                className="h-5 w-5 text-gray-600"
                aria-hidden="true"
              />
            ) : (
              <FunnelIcon
                className="h-5 w-5 text-gray-600"
                aria-hidden="true"
              />
            )}
          </span>
        </Popover.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        afterEnter={() => {
          inputRef.current?.focus();
        }}
        afterLeave={() => {
          search("");
        }}
      >
        <Popover.Panel className="absolute right-0 mt-2 max-h-96 w-56 origin-top-right divide-y divide-gray-100 overflow-auto rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="px-2 py-2">
            <input
              type="text"
              ref={inputRef}
              value={keyword}
              onChange={(e) => search(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (keyword !== "" && items.length > 0 && items[0]?.value) {
                    refine(items[0].value);
                  }
                }
              }}
              placeholder="Search for a state"
              className="w-full border-none px-2 py-1 text-gray-600 focus:outline-none"
            />
          </div>
          <div className="px-2 py-2">
            <div className="flex items-center text-xs">
              <button
                type="button"
                disabled={selected.length === 0}
                className="w-full cursor-pointer rounded px-2 py-2 hover:bg-gray-100 disabled:pointer-events-none disabled:opacity-50"
                onClick={() => {
                  selected.forEach((item) => {
                    refine(item.value);
                  });
                }}
              >
                Clear All
              </button>
            </div>
          </div>
          {items.map((item, index) => (
            <Switch.Group key={index}>
              <div key={item.value} className="px-2 py-2">
                <div
                  role="button"
                  className={cs(
                    "flex w-full cursor-pointer items-center rounded px-2 py-1 text-left text-xs hover:bg-gray-100",
                    keyword !== "" && index === 0 && "bg-gray-100",
                  )}
                  onClick={(e) => {
                    if (!e.isDefaultPrevented()) {
                      refine(item.value);
                    }
                  }}
                >
                  <ToggleSwitch
                    className="mr-2"
                    checked={item.isRefined}
                    onChange={() => {
                      refine(item.value);
                    }}
                  />
                  <span className="grow cursor-pointer">{item.label}</span>
                  <span className="rounded-full bg-gray-200 px-2 py-1 text-gray-800">
                    {item.count}
                  </span>
                </div>
              </div>
            </Switch.Group>
          ))}
          {items.length === 0 ? (
            <div className="px-2 py-3 text-center text-sm text-violet-400">
              No results found
            </div>
          ) : null}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default StateRefinement;
