import { Switch } from "@headlessui/react";
import { ReactNode } from "react";
import cs from "classnames";

const ToggleSwitch: React.FC<{
  checked: boolean;
  className?: string;
  label?: ReactNode;
  onChange?: (checked: boolean) => void;
}> = ({ checked, onChange, label, className }) => {
  return (
    <Switch
      checked={checked}
      onChange={onChange}
      className={cs(
        checked ? "bg-purple-800" : "bg-gray-400",
        "relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-purple-600 focus-visible:ring-opacity-75",
        className
      )}
    >
      {label ? <span className="sr-only">{label}</span> : null}
      <span
        aria-hidden="true"
        className={`${checked ? "translate-x-5" : "translate-x-0"}
    pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
      />
    </Switch>
  );
};

export default ToggleSwitch;
