import baseStates from "states-us";

const stateNameOverrides: Record<string, string> = {
  DC: "Washington D.C.",
};

export const states = baseStates
  .filter((state) => !state.territory)
  .map((state) => ({
    ...state,
    name: stateNameOverrides[state.abbreviation] ?? state.name,
  }));
