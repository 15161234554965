import React from "react";
import BaseRating from "react-star-ratings";

const Rating: React.FC<{
  rating?: number | undefined;
  changeRating?: (rating: number) => void | undefined;
  starDimension?: string;
  className?: string;
}> = (props) => {
  return (
    <span className={props.className}>
      <BaseRating
        rating={props.rating}
        starDimension={props.starDimension ?? "15px"}
        starSpacing="1px"
        starRatedColor="#9237eb"
        starHoverColor="#591e89"
        changeRating={props.changeRating}
      />
    </span>
  );
};

export default Rating;
