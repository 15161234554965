/* eslint-disable @next/next/no-img-element */
// import { LegacyRef, useState } from "react";
import { Popover } from "@headlessui/react";
import PencilIcon from "@heroicons/react/20/solid/PencilSquareIcon";
import PowerIcon from "@heroicons/react/20/solid/PowerIcon";
import UserCircleIcon from "@heroicons/react/20/solid/UserCircleIcon";
import CogIcon from "@heroicons/react/24/outline/Cog6ToothIcon";
import SubscriptionIcon from "@heroicons/react/24/outline/BellAlertIcon";
import { Activity } from "lucide-react";
import { signOut, useSession } from "next-auth/react";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";

const ProfileBox: React.FC<{
  style?: React.CSSProperties;
}> = (props) => {
  const router = useRouter();
  const { data: session, status } = useSession();

  return (
    <div className="flex items-center" style={props.style}>
      {session ? (
        <>
          <Popover className="relative">
            <div className="flex items-center">
              {session.user?.image ? (
                <Popover.Button className="mt-[-2px] overflow-hidden rounded-full border-4 shadow-sm outline-none hover:border-violet-400 focus:border-violet-400">
                  <img
                    alt="profile image"
                    src={session.user?.image}
                    style={{ width: "32px", height: "32px" }}
                  />
                </Popover.Button>
              ) : (
                <Popover.Button>
                  <CogIcon className="w-6 cursor-pointer text-gray-800 hover:text-black" />
                </Popover.Button>
              )}
            </div>

            <Popover.Panel className="absolute -right-5 z-20 mt-2 max-w-sm px-4">
              <div className="w-60 overflow-hidden whitespace-nowrap rounded-lg border border-gray-200 bg-white drop-shadow-xl">
                <div className="relative flex w-full flex-col bg-white p-4">
                  <Link
                    href="/profile"
                    className="cursor-pointer rounded p-2 text-sm font-extrabold text-gray-800 hover:bg-violet-100"
                  >
                    <UserCircleIcon className="-mt-[1px] mr-2 inline-block h-5 w-5 text-violet-800" />
                    Profile
                  </Link>
                  <Link
                    href="/activity"
                    className="hidden cursor-pointer rounded p-2 text-sm font-extrabold text-gray-800 hover:bg-violet-100"
                  >
                    <Activity className="-mt-[1px] mr-2 inline-block h-5 w-5 text-violet-800" />
                    Activity
                  </Link>
                  <Link
                    href="/theatre/request"
                    className="cursor-pointer rounded p-2 text-sm font-extrabold text-gray-800 hover:bg-violet-100"
                  >
                    <PencilIcon className="-mt-[1px] mr-2 inline-block h-5 w-5 text-violet-800" />
                    Theatre Requests
                  </Link>
                  <Link
                    href="/following"
                    className="cursor-pointer rounded p-2 text-sm font-extrabold text-gray-800 hover:bg-violet-100"
                  >
                    <SubscriptionIcon className="-mt-[1px] mr-2 inline-block h-5 w-5 text-violet-800" />
                    Following
                  </Link>
                  <button
                    className="cursor-pointer rounded p-2 text-left text-sm font-extrabold text-gray-800 hover:bg-violet-100"
                    onClick={() => signOut()}
                  >
                    <PowerIcon className="-mt-[1px] mr-2 inline-block h-5 w-5 text-violet-800" />
                    Sign Out
                  </button>
                </div>
              </div>
            </Popover.Panel>
          </Popover>
        </>
      ) : (
        <>
          {status != "loading" && (
            <>
              <Link
                href={`/login?redirect_url=${router.asPath}`}
                className="flex items-center space-x-2 whitespace-nowrap rounded px-4 py-2 text-sm font-bold text-gray-800 shadow-sm hover:shadow"
              >
                <UserCircleIcon className="w-5" />
                <span className="hidden md:inline">Log In / Sign up</span>
                <span className="md:hidden">Log In</span>
              </Link>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ProfileBox;
