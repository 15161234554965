import cn from "@/utils/cn";

const Logo: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <h1
      className={cn(
        "text-5xl font-extrabold leading-normal text-gray-700 md:text-[5rem]",
        className,
      )}
    >
      Gig<span className="text-purple-300">Intel</span>
    </h1>
  );
};
export default Logo;
