import {
  ReviewOutput,
  ReviewReplyOuput,
  UserOutput,
  VenueOutput,
} from "@/server/api/mappers";
import ChatBubbleIcon from "@heroicons/react/24/outline/ChatBubbleBottomCenterIcon";
import CheckBadgeIcon from "@heroicons/react/24/outline/CheckBadgeIcon";
import ClipboardIcon from "@heroicons/react/24/outline/ClipboardDocumentCheckIcon";
import MapPinIcon from "@heroicons/react/24/outline/MapPinIcon";
import TrashIcon from "@heroicons/react/24/outline/TrashIcon";
import formatRelative from "date-fns/formatRelative";
import Link from "next/link";
import React from "react";
import { trpc } from "../utils/trpc";
import Rating from "./ui/Rating";
import { TextTruncate } from "./ui/TextTruncate";

type BaseActivityProps = {
  timestamp: Date;
  venueId: string;
  user?: UserOutput | null;
};

const Timestamp: React.FC<BaseActivityProps> = (props) => {
  return (
    <div className="ml-2 text-xs font-normal italic text-gray-500">
      {formatRelative(new Date(props.timestamp), new Date())}
    </div>
  );
};

const TitleText: React.FC<React.PropsWithChildren> = (props) => (
  <div className="text-sm font-extrabold uppercase text-violet-800">
    {props.children}
  </div>
);

const ReviewCreated: React.FC<{ review: ReviewOutput } & BaseActivityProps> = (
  props,
) => (
  <Link
    href={`/theatre/${props.venueId}?reviewId=${props.review.id}`}
    className="flex cursor-pointer space-x-2 rounded-lg p-4 hover:bg-violet-100"
  >
    <div>
      <CheckBadgeIcon className="h-5 w-5 text-violet-400" />
    </div>
    <div className="grow">
      <div className="mb-2 flex items-center">
        <TitleText>Review posted</TitleText>
        <Timestamp {...props} />
      </div>
      <div className="text-gray-800">
        <div className="text-sm font-bold text-gray-600">
          {props.review.venue?.name}
        </div>
        <div>
          <Rating rating={props.review.rating} />
        </div>
        <TextTruncate text={props.review.comment} maxCharacters={200} />
      </div>
    </div>
  </Link>
);

const ReplyCreated: React.FC<
  {
    reply: ReviewReplyOuput;
  } & BaseActivityProps
> = (props) => (
  <Link
    href={`/theatre/${props.venueId}?reviewId=${props.reply.reviewId}&replyId=${props.reply.id}`}
    className="flex cursor-pointer space-x-2 rounded-lg p-4 hover:bg-violet-100"
  >
    <div>
      <ChatBubbleIcon className="h-5 w-5 text-violet-400" />
    </div>
    <div className="grow">
      <div className="mb-2 flex items-center">
        <TitleText>Reply posted</TitleText>
        <Timestamp {...props} />
      </div>
      <div className="mb-2 text-sm font-bold text-gray-600">
        {props.reply.review?.venue?.name}
      </div>
      <div className="text-gray-800">
        <TextTruncate text={props.reply.comment} maxCharacters={200} />
      </div>
    </div>
  </Link>
);

const VenueCreated: React.FC<{ venue: VenueOutput } & BaseActivityProps> = (
  props,
) => (
  <Link
    href={`/theatre/${props.venueId}`}
    className="flex cursor-pointer space-x-2 rounded-lg p-4 hover:bg-violet-100"
  >
    <div>
      <MapPinIcon className="h-5 w-5 text-violet-400" />
    </div>
    <div className="grow">
      <div className="mb-2 flex items-center">
        <TitleText>Theatre added</TitleText>
        <Timestamp {...props} />
      </div>
      <div className="text-sm font-bold text-gray-600">{props.venue.name}</div>
    </div>
  </Link>
);

const VenueUpdated: React.FC<{ venue: VenueOutput } & BaseActivityProps> = (
  props,
) => (
  <Link
    href={`/theatre/${props.venueId}`}
    className="flex cursor-pointer space-x-2 rounded-lg p-4 hover:bg-violet-100"
  >
    <div>
      <ClipboardIcon className="h-5 w-5 text-violet-400" />
    </div>
    <div className="grow">
      <div className="mb-2 flex items-center">
        <TitleText>Theatre updated</TitleText>
        <Timestamp {...props} />
      </div>
      <div className="text-sm font-bold text-gray-600">{props.venue.name}</div>
    </div>
  </Link>
);

const VenueRemoved: React.FC<{ venue: VenueOutput } & BaseActivityProps> = (
  props,
) => (
  <Link
    href={`/theatre/${props.venueId}`}
    className="flex cursor-pointer space-x-2 rounded-lg p-4 hover:bg-violet-100"
  >
    <div>
      <TrashIcon className="h-5 w-5 text-violet-400" />
    </div>
    <div className="grow">
      <div className="mb-2 flex items-center">
        <TitleText>Theatre removed</TitleText>
        <Timestamp {...props} />
      </div>
      <div className="text-sm font-bold text-gray-600">{props.venue.name}</div>
    </div>
  </Link>
);

export const ActivityTimeline: React.FC = () => {
  const timelineQuery = trpc.timeline.getRecent.useQuery();
  return (
    <div className="full">
      <ol className="block space-y-1">
        {timelineQuery.isLoading ? (
          <>
            {Array.from(Array(3).keys()).map((_, index, arr) => (
              <li key={index} className="flex space-x-2">
                <div className="flex flex-col justify-start space-y-2">
                  <div className="mt-[6px] shrink text-4xl leading-4">•</div>
                  {index < arr.length - 1 && (
                    <div className="ml-[6px] h-full border-l-2"></div>
                  )}
                </div>
                <div className="h-20 grow">
                  <span className="text-violet-800">... ...</span>
                </div>
              </li>
            ))}
          </>
        ) : (
          <>
            {timelineQuery.data?.map((item, index, arr) => {
              const details = JSON.parse(item.details ?? "") as {
                venueId: string;
              };
              return (
                <li key={item.id} className="flex space-x-2">
                  <div className="flex flex-col justify-start space-y-4">
                    <div className="mt-[15px] shrink text-4xl leading-4">•</div>
                    {index < arr.length - 1 && (
                      <div className="ml-[6px] h-full border-l-2"></div>
                    )}
                  </div>
                  <div className="grow">
                    {item.type === "REVIEW_CREATED" && (
                      <ReviewCreated
                        review={item.review!}
                        timestamp={item.timestamp}
                        venueId={details.venueId}
                        user={item.user}
                      />
                    )}
                    {item.type === "REPLY_CREATED" && (
                      <ReplyCreated
                        reply={item.reply!}
                        timestamp={item.timestamp}
                        venueId={details.venueId}
                        user={item.user}
                      />
                    )}
                    {item.type === "VENEUE_CREATED" && (
                      <VenueCreated
                        venue={item.venue!}
                        timestamp={item.timestamp}
                        venueId={details.venueId}
                        user={item.user}
                      />
                    )}
                    {item.type === "VENUE_UPDATED" && (
                      <VenueUpdated
                        venue={item.venue!}
                        timestamp={item.timestamp}
                        venueId={details.venueId}
                        user={item.user}
                      />
                    )}
                    {item.type === "VENUE_REMOVED" && (
                      <VenueRemoved
                        venue={item.venue!}
                        timestamp={item.timestamp}
                        venueId={details.venueId}
                        user={item.user}
                      />
                    )}
                  </div>
                </li>
              );
            })}
          </>
        )}
      </ol>
    </div>
  );
};

export default ActivityTimeline;
