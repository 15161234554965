import ContentfulMessageContent from "./ContentfulMessageContent";

const WelcomeBanner: React.FC = () => {
  return (
    <div className="border-l-4 pl-4">
      <ContentfulMessageContent
        entryId="4YyuQSkdDxaRp6Mf8sf9jt"
        className="text-sm text-gray-600"
      />
    </div>
  );
};
export default WelcomeBanner;
