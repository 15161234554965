import React from "react";

type MainProps<T extends React.ElementType> = React.PropsWithChildren<{
  className?: string;
  as?: T;
}>;

function Main<T extends React.ElementType>({
  children,
  className,
  ...props
}: MainProps<T>) {
  const AsComp = props.as ?? "main";
  return (
    <AsComp
      className={`container mx-auto flex flex-col justify-start p-4 ${className} min-w-[320px]`}
    >
      {children}
    </AsComp>
  );
}

export default Main;
