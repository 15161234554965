import { useMemo } from "react";

function findLastSpaceIndex(str: string): number {
  // Starting from the end of the string
  for (let i = str.length - 1; i >= 0; i--) {
    if (str[i] === " ") {
      // Return the index of the last space found
      return i;
    }
  }
  // If no space is found, return -1
  return -1;
}

export function truncateToLastWordWithinLimit(
  text: string,
  limit: number,
  truncateSuffix?: string
) {
  if (text.length <= limit) {
    return text;
  }
  const firstTruncatePass = text.slice(0, limit);
  const lastSpaceIndex = findLastSpaceIndex(firstTruncatePass);
  const splitInMiddleOfWord = text[limit] != " ";

  return (
    (splitInMiddleOfWord
      ? firstTruncatePass.slice(0, lastSpaceIndex)
      : firstTruncatePass) + (truncateSuffix ?? "")
  );
}

export const TextTruncate: React.FC<{
  text?: string | undefined | null;
  maxCharacters: number;
}> = ({ text, maxCharacters }) => {
  const isString = typeof text === "string";
  const truncatedText = useMemo(
    () => (isString ? truncateToLastWordWithinLimit(text, maxCharacters) : ""),
    [text, maxCharacters, isString]
  );
  return (
    <>
      {truncatedText}
      {isString && text.length > maxCharacters ? "..." : null}
    </>
  );
};
