import { Document, BLOCKS, INLINES } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const ContentfulRichText: React.FC<{ content: Document }> = (props) => (
  <>
    {documentToReactComponents(props.content, {
      renderNode: {
        [BLOCKS.HEADING_1]: (node, children) => (
          <h1 className="mb-3 text-3xl">{children}</h1>
        ),
        [BLOCKS.HEADING_2]: (node, children) => (
          <h2 className="mb-3 text-2xl">{children}</h2>
        ),
        [BLOCKS.HEADING_3]: (node, children) => (
          <h3 className="mb-3 text-xl">{children}</h3>
        ),
        [BLOCKS.PARAGRAPH]: (node, children) => (
          <p className="mb-3 last:mb-0">{children}</p>
        ),
        [BLOCKS.UL_LIST]: (node, children) => (
          <ul className="list-disc pl-4">{children}</ul>
        ),
        [INLINES.HYPERLINK]: (node, children) => (
          <a
            className="text-blue-700 underline hover:text-blue-800"
            href={node.data.uri}
          >
            {children}
          </a>
        ),
      },
    })}
  </>
);

export default ContentfulRichText;
