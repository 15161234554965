import ActivityTimeline from "@/components/ActivityTimeline";
import Footer from "@/components/Footer";
import Main from "@/components/Main";
import ProfileBox from "@/components/ProfileBox";
import StateRefinement from "@/components/StateRefinement";
import Logo from "@/components/ui/Logo";
import Rating from "@/components/ui/Rating";
import Spinner from "@/components/ui/Spinner";
import WelcomeBanner from "@/components/WelcomeBanner";
import { env } from "@/env.mjs";
import { VenueOutput } from "@/server/api/mappers";
import { searchStorage } from "@/store/searchStorage";
import { trpc } from "@/utils/trpc";
import ChevronDoubleDownIcon from "@heroicons/react/20/solid/ChevronDoubleDownIcon";
import MagnifyingGlassIcon from "@heroicons/react/24/solid/MagnifyingGlassIcon";
import algoliasearch from "algoliasearch/lite";
import cs from "classnames";
import type { Hit } from "instantsearch.js";
import Link from "next/link";
import React, { useCallback, useMemo, useState } from "react";
import {
  Highlight,
  InstantSearch,
  useConfigure,
  useCurrentRefinements,
  useInfiniteHits,
  useInstantSearch,
  useSearchBox,
  useSortBy,
} from "react-instantsearch";
import Sticky from "react-stickynode";

export const searchClient = algoliasearch(
  env.NEXT_PUBLIC_ALGOLIA_APP_ID,
  env.NEXT_PUBLIC_ALGOLIA_SEARCH_KEY,
);

export const searchIndexes = {
  sortedByName: `${env.NEXT_PUBLIC_ALGOLIA_INDEX_PREFIX}_venues_name_desc`,
  default: `${env.NEXT_PUBLIC_ALGOLIA_INDEX_PREFIX}_venues`,
};

const HomePage: React.FC = () => {
  const [isShowAllEnabled, setIsShowAll] = useState(false);
  const getAllVenuesQuery = trpc.venue.getAll.useQuery(undefined, {
    enabled: isShowAllEnabled,
  });
  const initialUiState = useMemo(
    () => ({
      [searchIndexes.sortedByName]: searchStorage.load(),
    }),
    [],
  );

  return (
    <>
      <InstantSearch
        searchClient={searchClient}
        indexName={searchIndexes.sortedByName}
        onStateChange={({ uiState, setUiState }) => {
          searchStorage.save({
            query: uiState[searchIndexes.sortedByName]?.query ?? "",
            refinementList:
              uiState[searchIndexes.sortedByName]?.refinementList ?? {},
          });
          setUiState(uiState);
        }}
        initialUiState={initialUiState}
      >
        <Main>
          <div className="mb-5 flex w-full items-center justify-between">
            <Logo />
            <ProfileBox />
          </div>
          <WelcomeBanner />
        </Main>
        <>
          <div className="">
            <StickySearch />
          </div>
          <Main className="space-y-4">
            <div className="grid grid-cols-12 space-y-10 md:gap-10 md:space-y-0">
              <div className="col-span-12 md:col-span-6 lg:col-span-7 xl:col-span-8">
                <VenueListSwitch
                  getAllVenuesQuery={getAllVenuesQuery}
                  isShowAllEnabled={isShowAllEnabled}
                  showAll={() => setIsShowAll(true)}
                />
              </div>
              <div className="col-span-12 md:col-span-6 lg:col-span-5 xl:col-span-4">
                <>
                  <div className="mb-3">
                    <span className="text-xl text-gray-800">
                      Recent Activity
                    </span>
                  </div>
                  <ActivityTimeline />
                </>
              </div>
            </div>
            <Footer />
          </Main>
        </>
      </InstantSearch>
    </>
  );
};

export const VenueListSwitch: React.FC<{
  isShowAllEnabled: boolean;
  getAllVenuesQuery: { isLoading: boolean; data?: VenueOutput[] | null };
  showAll: () => void;
}> = ({ isShowAllEnabled, getAllVenuesQuery, showAll }) => {
  const { query, refine } = useSearchBox();
  const { items: refinements } = useCurrentRefinements();
  const shouldShowAll =
    isShowAllEnabled && query.length === 0 && refinements.length == 0;

  return (
    <>
      {shouldShowAll ? (
        <>
          {getAllVenuesQuery.isLoading ? (
            <div className="flex h-96 items-center justify-center text-center">
              <div>
                <h3 className="text-2xl font-extrabold text-gray-700">
                  <Spinner className="mr-3 inline h-6 w-6 animate-spin text-violet-400" />
                  This might take a minute...
                </h3>
              </div>
            </div>
          ) : null}
          {getAllVenuesQuery.data ? (
            <VenueList venues={getAllVenuesQuery.data} />
          ) : null}
        </>
      ) : null}
      <div className={cs(shouldShowAll && "hidden")}>
        <InifiniteVenueHits
          isShowAllEnabled={isShowAllEnabled}
          showAll={() => {
            showAll();
            refine("");
          }}
        />
      </div>
    </>
  );
};

const InifiniteVenueHits: React.FC<{
  showAll: () => void;
  isShowAllEnabled: boolean;
}> = ({ showAll, isShowAllEnabled }) => {
  const { status } = useInstantSearch();
  const infiniteHits = useInfiniteHits<VenueOutput>({});
  const { query } = useSearchBox();
  const { items: refinements } = useCurrentRefinements();
  const hasQueriedVenues = query.length > 0 || refinements.length > 0;

  return (
    <div>
      {infiniteHits.hits.length > 0 ? (
        <ol className="list-none  divide-y">
          <VenueHistList
            hits={infiniteHits.hits}
            hasQueriedVenues={hasQueriedVenues}
          />
        </ol>
      ) : null}
      {status !== "loading" &&
      infiniteHits.hits.length === 0 &&
      hasQueriedVenues ? (
        <div className="flex h-96 flex-col items-center justify-center space-y-3">
          <h3 className="block text-center text-2xl font-extrabold">
            Oops. We can{"'"}t seem to find what you{"'"}re looking for.
          </h3>
          <h3 className="block text-center text-lg text-gray-500">
            Think we{"'"}re missing something?{" "}
            <Link
              href="/theatre/request/new"
              className="text-gray-600 underline hover:text-gray-800"
            >
              Request a theatre
            </Link>
          </h3>
        </div>
      ) : null}
      {infiniteHits.hits.length == 0 ? null : (
        <div className="mt-2 flex space-x-3">
          <button
            type="button"
            disabled={infiniteHits.isLastPage}
            onClick={infiniteHits.showMore}
            className={cs(
              "btn-default",

              infiniteHits.isLastPage && "pointer-events-none opacity-50",
            )}
          >
            <span className="whitespace-nowrap">
              <ChevronDoubleDownIcon className="-mt-1 inline w-5" /> Show More
            </span>
          </button>
          {isShowAllEnabled && query.length === 0 ? null : (
            <button type="button" onClick={showAll} className="btn-white">
              Show All
            </button>
          )}
        </div>
      )}
    </div>
  );
};

const VenueHistList: React.FC<{
  hits: Hit<VenueOutput>[];
  hasQueriedVenues: boolean;
}> = ({ hits, hasQueriedVenues }) => {
  return (
    <ol className="list-none">
      {hits.map((venue) => (
        <li className="cursor-pointer" key={venue.id}>
          <UnifiedVenueItem venue={venue} isHit />
        </li>
      ))}
      {hasQueriedVenues ? (
        <li className="py-3">
          <h3 className="block text-gray-500">
            Think we{"'"}re missing something?{" "}
            <Link
              href="/theatre/request/new"
              className="text-gray-600 underline hover:text-gray-800"
            >
              Request a theatre
            </Link>
          </h3>
        </li>
      ) : null}
    </ol>
  );
};

export const VenueList: React.FC<{ venues: VenueOutput[] }> = ({ venues }) => {
  return (
    <ol className="list-none">
      {venues.map((venue) => (
        <li className="cursor-pointer" key={venue.id}>
          <UnifiedVenueItem venue={venue} />
        </li>
      ))}
      <li className="py-3">
        <h3 className="block text-gray-500">
          Think we{"'"}re missing something?{" "}
          <Link
            href="/theatre/request/new"
            className="text-gray-600 underline hover:text-gray-800"
          >
            Request a theatre
          </Link>
        </h3>
      </li>
    </ol>
  );
};

const UnifiedVenueItem: React.FC<{
  venue: Hit<VenueOutput> | VenueOutput;
  isHit?: boolean;
}> = ({ venue, isHit }) => {
  return (
    <Link
      href={`/theatre/${venue.id}`}
      className="block w-full rounded p-2 hover:bg-gray-100"
    >
      <div className="flex items-center space-x-2">
        <div className="">
          {isHit ? (
            <>
              <Highlight
                hit={venue as Hit<VenueOutput>}
                attribute="name"
                classNames={{
                  highlighted: "bg-green-100",
                }}
              />
              {venue.state && venue.city ? (
                <>
                  &nbsp;
                  <span className="whitespace-nowrap text-xs italic text-gray-600">
                    {venue.city}, {venue.state}
                  </span>
                </>
              ) : null}
            </>
          ) : (
            <>
              {venue.name}
              {venue.state && venue.city ? (
                <>
                  &nbsp;
                  <span className="whitespace-nowrap text-xs italic text-gray-600">
                    {venue.city}, {venue.state}
                  </span>
                </>
              ) : null}
            </>
          )}
        </div>
      </div>
      <div className="-mt-2">
        <Rating rating={venue.averageRating} starDimension={"12px"} />
      </div>
    </Link>
  );
};

export const SearchInput: React.FC = () => {
  const [isFocused, setIsFocused] = useState(false);
  const { query, refine } = useSearchBox();
  const [searchValue, setSearchValue] = useState(query ?? "");
  const { refine: sortRefine } = useSortBy({
    items: [
      {
        label: "Default",
        value: searchIndexes.default,
      },
      {
        label: "Name (asc)",
        value: searchIndexes.sortedByName,
      },
    ],
  });
  const applySearch = useCallback(
    (search: string) => {
      setSearchValue(search);
      refine(search);
    },
    [setSearchValue, refine],
  );
  return (
    <div className="relative w-full">
      <span className="absolute inset-y-0 left-0 px-3 py-2">
        <MagnifyingGlassIcon
          className={cs("w-6", isFocused ? "text-violet-600" : "text-gray-300")}
        />
      </span>
      <input
        className={cs(
          "w-full rounded-full border py-2 pl-10 outline-none focus:border-violet-600",
          searchValue.length === 0 && "pr-4",
          searchValue.length > 0 && "pr-14",
        )}
        role="presentation"
        placeholder="Search for a theatre"
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        type="text"
        value={searchValue}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onChange={(e) => {
          const value = e.target.value;
          if (value.length > 0) {
            sortRefine(searchIndexes.default);
          } else {
            sortRefine(searchIndexes.sortedByName);
          }
          applySearch(value);
        }}
      />
      {/* <span className="absolute inset-y-0 right-0 px-4 py-2"> */}
      {query.length > 0 ? (
        <button
          type="button"
          className="absolute inset-y-0 right-0 px-4 py-2 text-sm font-normal text-gray-400 hover:text-gray-600"
          onClick={() => {
            sortRefine(searchIndexes.sortedByName);
            applySearch("");
          }}
        >
          clear
        </button>
      ) : null}
      {/* </span> */}
    </div>
  );
};
export const MemoizedSearchInput = React.memo(SearchInput);

const StickySearch: React.FC = () => {
  const [isSticky, setIsSticky] = React.useState(false);
  useConfigure({
    hitsPerPage: 20,
  });

  const handleStateChange = useCallback((status: { status: number }) => {
    if (status.status === Sticky.STATUS_FIXED) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  }, []);

  return (
    <Sticky
      innerActiveClass="bg-white shadow-lg"
      innerZ={10}
      onStateChange={handleStateChange}
    >
      <div>
        <div className="container mx-auto flex items-center justify-between space-x-3 px-4 py-3">
          <div className="flex w-full items-center space-x-3 lg:w-7/12 xl:w-2/3">
            <div className="grow">
              <MemoizedSearchInput />
            </div>
            <StateRefinement />
          </div>
          {isSticky && <ProfileBox />}
        </div>
      </div>
    </Sticky>
  );
};

export default HomePage;
