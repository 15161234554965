import Link from "next/link";
import { FaTiktok, FaInstagram } from "react-icons/fa";
import cn from "@/utils/cn";

const Footer: React.FC<{ className?: string }> = (props) => {
  return (
    <div
      className={cn(
        "flex flex-col space-y-2 whitespace-nowrap py-3 text-sm sm:flex-row sm:justify-center sm:space-x-3 sm:space-y-0",
        props.className
      )}
    >
      <Link href="/tos" className="font-bold text-gray-600 hover:text-gray-800">
        Terms of Service
      </Link>
      <span className="hidden sm:inline">|</span>
      <Link
        href="/privacy"
        className="font-bold text-gray-600 hover:text-gray-800"
      >
        Privacy Policy
      </Link>
      <span className="hidden sm:inline">|</span>
      <Link
        href="/contact"
        className="font-bold text-gray-600 hover:text-gray-800"
      >
        Contact Us
      </Link>
      <span className="hidden sm:inline">|</span>
      <Link
        href="https://www.buymeacoffee.com/gigintel/wishlist"
        target="_blank"
        className="font-bold text-gray-600 hover:text-gray-800"
      >
        Support Us
      </Link>
      <span className="hidden sm:inline">|</span>
      <Link
        href="/feedback"
        className="font-bold text-gray-600 hover:text-gray-800"
      >
        Feedback
      </Link>
      <span className="hidden sm:inline">|</span>
      <Link
        rel="noreferrer"
        target="_blank"
        href="https://www.tiktok.com/@gigintel.art?is_from_webapp=1&sender_device=pc"
        className="font-bold text-gray-600 hover:text-gray-800 sm:inline-flex sm:items-center"
      >
        <FaTiktok className="text-lg" />
      </Link>
      <Link
        rel="noreferrer"
        target="_blank"
        href="https://www.instagram.com/gigintel.art/"
        className="font-bold text-gray-600 hover:text-gray-800 sm:inline-flex sm:items-center"
      >
        <FaInstagram className="text-lg" />
      </Link>
    </div>
  );
};

export default Footer;
